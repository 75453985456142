import { FC, useState } from "react";

import { Button, Modal } from "antd";

import { InviteForm } from "components/common";

import "./CreateInviteModal.scss";

const CreateInviteModal: FC = () => {
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <>
      <Button
        className="create-invite-button"
        type="primary"
        onClick={ () => setIsOpen(true) }
      >
        Invite
      </Button>

      <Modal
        className="create-invite-form"
        open={ isOpen }
        onCancel={ () => setIsOpen(false) }
        footer={ null }
      >
        { isOpen && (
          <InviteForm
            afterEvent={ () => setIsOpen(false) }
          />
        ) }
      </Modal>
    </>
  )
}

export default CreateInviteModal;
