import { Button, Form, Input, Modal, message } from "antd";

import api from "services/api.service";
import { useAppDispatch } from "store";
import { Loading } from "components/common";
import emailIcon from "data/icons/email.svg";
import closeIcon from "data/icons/close-gray.svg";
import { getContract, getContractEquipments } from "store/contracts/contracts.thunks";

import "./ContractPublishModal.scss";

interface IProps {
  id: number;
  name: string;
  isPublishModal: boolean;
  setIsPublishModal: (value: boolean) => void;
}

const ContractPublishModal = ({ id, name, isPublishModal, setIsPublishModal }: IProps) => {
  const [ form ] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleCompleteForm = async (values: any) => {
    try {
      if (id) {
        setIsPublishModal(false);
        await api.publishContract(id, values.email);
  
        dispatch(getContract(id));
        dispatch(getContractEquipments(+id));
      }
    } catch (e) {
      // @ts-ignore
      message.error(e?.response.data.detail || JSON.stringify(e?.response.data) );
    }
    
  };

  return (
    <Modal
      open={ isPublishModal }
      closeIcon={
        <img
          alt=""
          src={ closeIcon }
          onClick={ () => setIsPublishModal(false) }
        />
      }
      footer={ null }
      centered
      className="contract-publish-modal"
      width={ 460 }
      onCancel={ () => setIsPublishModal(false) }
    >
      <div className="contract-publish-content">
        <h4>Publish & Invite Contractor</h4>

        <div>
          <span className="contract-publish-content-accent">{ name }</span> will be invited to join the contract and notified. <br/>
          Enter email for notification bellow.
        </div>

        <Form
          className="invite-form-new"
          name="invite-form"
          layout="vertical"
          autoComplete="off"
          form={ form }
          onFinish={ handleCompleteForm }
        >
          <div className="contract-publish-text">
            <Form.Item
              className="mg-btn-0"
              label="Email"
              name="email"
              rules={ [
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please input correct email!" },
              ] }
            >
              <Input
                placeholder="yourname@mail.com"
                prefix={ <img src={ emailIcon } alt=""/> }
              />
            </Form.Item>
          </div>

          <div className="buttons-wrapper">
            <Button
              type="primary"
              htmlType="submit"
              style={ { width: "100%" } }
            >
              <Loading isLoading={ false }>
                Publish & Invite
              </Loading>
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ContractPublishModal;
