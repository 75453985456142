import { FC, useState } from "react";

import { Button, Layout, Modal } from "antd";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import mainBgPrimary from "data/images/main-bg-primary.svg";
import mainBgSecondary from "data/images/main-bg-secondary.svg";
import loginIcon from "data/icons/login.svg";
import mainLogo from "data/icons/hydra-logo-main.svg";
import mainPromo from "data/images/main-promo.svg";
import { LoginForm } from "components/common";

import "./LandingPage.scss";

const LandingPage: FC = () => {
  const { isMobile } = useAppSelector(getUserSelector);
  const [ isLoginOpen, setIsLoginOpen ] = useState(false);

  return (
    <Layout className={ `landing-page-wrapper ${ isMobile ? "mobile" : "" }` }>
      <div className="header">
        <img src={ mainLogo } alt=""/>

        <a
          className="login-button"
          onClick={ () => setIsLoginOpen(true) }
        >
          <Button type="primary" style={ { background: "#0C5463", marginLeft: "auto" } }>
            <img src={ loginIcon } alt=""/>
            Log in
          </Button>
        </a>

        <Modal
          open={ isLoginOpen }
          onCancel={ () => setIsLoginOpen(false) }
          footer={ null }
          width={ 440 }
        >
          { isLoginOpen && (
            <div style={ { padding: "24px 16px 8px 16px" } }>
              <LoginForm />
            </div>
          ) }
        </Modal>
      </div>

      <img src={ mainBgPrimary } alt="" className="bg"/>
      <img src={ mainBgSecondary } alt="" className="bg"/>

      <div className="group">
        <Layout className="form-wrapper">
          <h1>Service Management <br/> Platform</h1>

          <div className="subtitle">
            Comprehensive web-based platform designed to streamline and optimize <br/>
           the management of infrastructure for businesses of all sizes.
          </div>
        </Layout>

        <img src={ mainPromo } alt="" className="promo"/>
      </div>
    </Layout>
  )
}

export default LandingPage;
