import { FC } from "react";

import { Link } from "react-router-dom";
import { Button } from "antd";

import { RouteLinks } from "services/router.service";

import "./CreateContractModal.scss";

const CreateContractModal: FC = () => {
  return (
    <Link
      to={ RouteLinks.PROTECTED.CONTRACTS_ROUTES.NEW }
      className="create-contract-button"
    >
      <Button>
        New contract
      </Button>
    </Link>
  )
}

export default CreateContractModal;
