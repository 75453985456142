import { FC } from "react";

import { Header } from "antd/es/layout/layout";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";

import { RouteLinks } from "services/router.service";
import appLogo from "data/icons/hydra-logo-main.svg";
import loginIcon from "data/icons/login.svg";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { authorizedPages } from "services/data.service";
import { Notifications, UserMenu } from "components/common";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { CreateCaseModal } from "components/cases";
import { CreateContractModal } from "components/contracts";

import "./TopBar.scss";

const TopBar: FC = () => {
  const loc = useLocation();
  const navigate = useNavigate();

  const { isAuthorized, isMobile } = useAppSelector(getUserSelector);
  const { currentContract } = useAppSelector(getContractsSelector);

  const extraLinks = [
    {
      value: RouteLinks.PROTECTED.DOWNLOAD_ROUTES.ADD_FILE,
      title: "Add File",
    },
    {
      value: RouteLinks.PROTECTED.DOWNLOAD_ROUTES.FILE,
      title: "File",
    },
    {
      value: RouteLinks.PROTECTED.CASES.CASE,
      title: "Case",
    },
    {
      value: RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT,
      title: currentContract ? currentContract?.identifier + " - " + currentContract?.contractor?.name : "Loading...",
    },
    {
      value: RouteLinks.PROTECTED.CONTRACTORS.NEW,
      title: "Add contractor",
    },
    {
      value: RouteLinks.PROTECTED.CONTRACTS_ROUTES.INVITATION,
      title: "Invitations",
    },
  ]

  return (
    <Header className={ `app-header ${ isMobile ? "mobile" : "" }` }>
      { isAuthorized ? (
        <>
          { !isMobile ? (
            <p className="page-title">
              { authorizedPages.find((page) => page.link === loc.pathname)?.label
                || extraLinks.find((link) => loc.pathname.indexOf(link.value) !== -1)?.title }
              {loc.pathname.includes(RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT) && currentContract && (
                <div className="status">
                  {currentContract.status}
                </div>
              )}
            </p>
          ) : (
            <>
              <UserMenu/>
              <Notifications />
            </>
          ) }

          <CreateContractModal />
          <CreateCaseModal />

          { !isMobile && (
            <>
              <Notifications />
              <UserMenu />
            </>
          ) }
        </>
        ) : (
        <>
          <NavLink to={ RouteLinks.MAIN }>
            <img
              alt=""
              className="app-logo"
              src={ appLogo }
            />
          </NavLink>

          { (
            loc.pathname !== RouteLinks.AUTH &&
            loc.pathname !== RouteLinks.INVITE_COMPLETE
          ) && (
            <a
              className="login-button"
              onClick={ () => navigate(RouteLinks.MAIN) }
              style={ {
                margin: "10px 20px 0 auto",
              } }
            >
              <Button
                type="primary"
                style={ {
                  border: "1px solid #0C5463",
                  background: "#0C5463",
                } }
              >
                <img src={ loginIcon } alt=""/>
                Log in
              </Button>
            </a>
          ) }
        </>
      ) }
    </Header>
  )
}

export default TopBar;
