import { useEffect, useState } from "react";

import { Button, DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";

import plusBlue from "data/icons/plus-blue.svg";
import { IContractEquipment } from "store/contracts/contracts.types";
import { extraPortion } from "services/data.service";
import api from "services/api.service";

import "./ContractEquipmentsTableEdit.scss";

interface IProps {
  equipmentList: IContractEquipment[];
  slaCiOptions?: { value: number, label: string }[];
  equipmentsForm: any;
}

const ContractEquipmentsTableEdit = ({ equipmentList, slaCiOptions, equipmentsForm }: IProps) => {
  // const [ form ] = Form.useForm();
  const [ products, setProducts ] = useState<{ value: number, label: any }[]>([]);

  const initialValues = {
    equipment: equipmentList.map(contractEquipment => ({
      ...contractEquipment,
      product_number: contractEquipment.product?.product_number,
      description: contractEquipment.product?.description,
      sla_ci: { value: contractEquipment.sla_ci?.id, label: contractEquipment.sla_ci?.marker },
      date_begin: dayjs(contractEquipment.date_begin),
      date_end: dayjs(contractEquipment.date_end),
      product: {
        id: contractEquipment.product?.id,
        value: contractEquipment.product?.product_number,
        label: <><b>{ contractEquipment.product?.product_number }</b> | { contractEquipment.product?.description }</>,
      },
    })),
  };

  const getProducts = async (value: string) => {
    const response = await api.getProducts({ search: value, category: null }, extraPortion, 0);

    setProducts(response.data.results.map((el) => ({
      value: el.id,
      label: <><b>{ el.product_number }</b> | { el.description }</>,
    })));
  }

  const handleCompleteForm = async (values: any) => {
    console.log(values);
  };
  
  useEffect(() => {
    equipmentsForm.setFieldsValue(initialValues);
  }, [ equipmentList ]);

  useEffect(() => {
    getProducts("");
  }, []);
  
  return (
    <div className="contact-equipment-table">
      <div className="contact-equipment-table-header">
        <div className="contact-equipment-table-header-item">
          Product number
        </div>

        <div className="contact-equipment-table-header-item">
          Description
        </div>

        <div className="contact-equipment-table-header-item">
          Serial Number
        </div>

        <div className="contact-equipment-table-header-item">
          Start date
        </div>

        <div className="contact-equipment-table-header-item">
          End date
        </div>

        <div className="contact-equipment-table-header-item">
          Support type
        </div>
      </div>

      <Form
        name="contact-equipment"
        className="contact-equipment-table-form"
        layout="horizontal"
        autoComplete="off"
        form={ equipmentsForm }
        onFinish={ handleCompleteForm }
        initialValues={ initialValues }
      >
        <Form.List name="equipment">
          {(fields, { add, remove }) => {
            return (
              <>
                <Button
                  type="dashed"
                  // className="attach-button"
                  style={ { minWidth: "-webkit-fill-available", margin: "7px 5px" } }
                  onClick={ () => add("", 0) }
                >
                  <img src={ plusBlue } alt="" />
                  Add row
                </Button>

                {fields.map((field, index) => (
                  <div key={ field.key } className="contact-equipment-form-item">
                    <div className="contact-equipment-form-item-id">
                      {index + 1}.
                    </div>

                    <Form.Item
                      name={ [ index, "product" ] }
                      rules={ [ { required: true, message: "Please select product!" } ] }
                      style={ { width: "200%", maxWidth: "350px" } }
                    >
                      <Select
                        options={ products }
                        showSearch
                        onSearch={ (value) => getProducts(value) }
                        filterOption={ false }
                        placeholder="Choose Product"
                        notFoundContent={ null }
                      />
                    </Form.Item>

                    <Form.Item
                      name={ [ index, "serial_number" ] }
                      rules={ [ { required: true } ] }
                      style={ { width: "100%" } }
                    >
                      <Input placeholder="Serial Number" />
                    </Form.Item>

                    <Form.Item
                      name={ [ index, "date_begin" ] }
                      style={ { width: "100%" } }
                    >
                      <DatePicker style={ { width: "100%" } } />
                    </Form.Item>

                    <Form.Item
                      name={ [ index, "date_end" ] }
                      style={ { width: "100%" } }
                    >
                      <DatePicker style={ { width: "100%" } } />
                    </Form.Item>

                    <Form.Item
                      name={ [ index, "sla_ci" ] }
                      rules={ [ { required: true } ] }
                      style={ { width: "100%" } }
                    >
                      <Select
                        notFoundContent={ null }
                        options={ slaCiOptions }
                        placeholder="Support Type"
                      />
                    </Form.Item>
                  </div>
                ))}
              </>
            );
          }}
        </Form.List>
      </Form>
    </div>
  );
};

export default ContractEquipmentsTableEdit;
