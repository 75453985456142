import { FC, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getCurrentCase } from "store/cases/cases.thunks";
import backIcon from "data/icons/arrow-left.svg";
import { CaseComments, ContactInfoCard, CurrentCaseCard } from "components/cases";
import { getUserSelector } from "store/user/user.selector";
import { ICaseActionsLog } from "store/cases/cases.types";
import api from "services/api.service";

import "./CurrentCasePage.scss";

const CurrentCasePage: FC = () => {
  const navigate = useNavigate();
  const { caseId } = useParams();
  const dispatch = useAppDispatch();

  const { isMobile } = useAppSelector(getUserSelector);
  const [ dataDisplay, setDataDisplay ] = useState<"info" | "contacts">("info")
  const [ actionsLog, setActionsLog ] = useState<ICaseActionsLog>();

  const onLoadActionsLog = async () => {
    if (caseId) {
      const response = await api.getActionsLog(caseId);

      setActionsLog(response.data);
    }
  };
  
  useEffect(() => {
    caseId && dispatch(getCurrentCase(caseId, () => {
      navigate("/not-found");
    }));
  }, [])

  return (
    <div className="default-page-wrapper current-case-page-wrapper">
      <Button
        className="back-button"
        type="link"
        onClick={ () => navigate(-1) }
      >
        <img src={ backIcon } alt="" />
        Back
      </Button>

      <div className="cards-wrapper">
        <CurrentCaseCard
          dataDisplay={ dataDisplay }
          setDataDisplay={ setDataDisplay }
          onLoadActionsLog={ onLoadActionsLog }
        />

        { !isMobile && <ContactInfoCard /> }
      </div>

      <CaseComments
        actionsLog={ actionsLog }
        onLoadActionsLog={ onLoadActionsLog }
      />
    </div>
  )
}

export default CurrentCasePage;
