import { ChangeEvent, FC, useEffect, useMemo } from "react";

import { Input, Tabs, TabsProps } from "antd";
import debounce from "lodash.debounce";

import { useAppDispatch, useAppSelector } from "store";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { updateContractsFilter, updateContractsPagination, updateContractsSort } from "store/contracts/contracts.actions";
import searchIcon from "data/icons/search.svg";
import { getContracts } from "store/contracts/contracts.thunks";
import { initialState } from "store/contracts/contracts.reducer";

import "./ContractsHeader.scss";

const tabs: TabsProps[ "items" ] = [
  {
    key: "service",
    label: "Service",
  },
  {
    key: "supply",
    label: "Warranty",
  },
]

const ContractsHeader: FC = () => {
  const dispatch = useAppDispatch();
  const { filter, categories, pagination } = useAppSelector(getContractsSelector);

  const items = [
    { key: "all", label: "All" },
    ...categories.map((item) => ({
      key: item.id.toString(),
      label: item.name,
    })),
    { key: "null", label: "Undefined" },
  ];

  const handleChangeCategory = (category: string) => {
    dispatch(updateContractsFilter({
      // @ts-ignore
      category:
        category === "all" ? null
          : category === "null" ? "null"
            : categories.find((el) => el.id == +category)?.id,

    }))
    dispatch(updateContractsPagination(1, pagination.rowsPerPage));
    dispatch(getContracts());
  }

  useEffect(() => {
    return () => {
      if (window.location.pathname.indexOf("/contracts") === -1) {
        dispatch(updateContractsFilter(initialState.filter));
        dispatch(updateContractsSort(initialState.sort));
        dispatch(updateContractsPagination(initialState.pagination.page, initialState.pagination.rowsPerPage));
      }
    }
  }, [])

  // const handleChangeType = (type: string) => {
  //   dispatch(updateContractsFilter({
  //     ...filter,
  //     type: type as "service" | "supply",
  //   }))
  //   dispatch(updateContractsPagination(1, pagination.rowsPerPage));
  //   dispatch(getContracts());
  // }

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateContractsFilter({
      ...filter,
      search: e.target.value,
    }))
    dispatch(updateContractsPagination(1, pagination.rowsPerPage));
  }

  const updateInfo = () => {
    dispatch(getContracts());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  return (
    <div className="contracts-header-wrapper">
      <Tabs
        items={ items }
        // activeKey={ filter.type }
        activeKey={ filter.category === null ? "all" : filter.category.toString() }
        onChange={ handleChangeCategory }
      />

      <Input
        value={ filter.search }
        prefix={ <img src={ searchIcon } alt="" /> }
        placeholder="Search"
        onChange={ (e) => {
          handleChangeSearch(e);
          debouncedResults()
        } }
        className="search-input"
      />
    </div>
  )
}

export default ContractsHeader;
