import { FC, useEffect, useState } from "react";

import { Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

import { ICase } from "store/cases/cases.types";
import { getSeverityTitle } from "services/title.service";
import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { getCasesSelector } from "store/cases/cases.selector";
import { Loading } from "components/common";
import { getCases } from "store/cases/cases.thunks";
import { updateCasesSort } from "store/cases/cases.actions";
import { formatDateForApi } from "services/data.service";
import { RouteLinks } from "services/router.service";
import verified from "data/icons/verified.svg";
import hydraAccount from "data/icons/hydra-account.svg";

import "./CasesTable.scss";

const CasesTable: FC = () => {
  const navigate = useNavigate();
  const { isAdmin } = useAppSelector(getUserSelector);
  const { cases, isCasesLoading, sort } = useAppSelector(getCasesSelector);
  const dispatch = useAppDispatch();

  const [ updateState, setUpdateState ] = useState(false);
  useEffect(() => setUpdateState(!updateState), [ isCasesLoading ])

  const columns: ColumnsType<ICase> = [
    {
      title: "Case",
      dataIndex: "custom_id",
      key: "custom_id",
      render: (value, item) => <p className={ item.status === "open" ? "active" : "" }>{ value }</p>,
      sortOrder: sort.column === "custom_id" ? sort.direction : undefined,
      sorter: sort.column === "custom_id",
      showSorterTooltip: false,
      width: 150,
      onHeaderCell: () => ({
        id: "cases-header-cell",
        onClick: () => {
          dispatch(updateCasesSort({
            column: "custom_id",
            direction: sort.column === "custom_id" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getCases())
        },
      }),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      className: "subject-cell",
      render: (value, item) => <p className={ item.status === "open" ? "active" : "" }>{ value }</p>,
      sortOrder: sort.column === "subject" ? sort.direction : undefined,
      sorter: sort.column === "subject",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateCasesSort({
            column: "subject",
            direction: sort.column === "subject" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getCases())
        },
      }),
    },
    {
      title: "Opening Date",
      dataIndex: "created_at",
      key: "created_at",
      sortOrder: sort.column === "created_at" ? sort.direction : undefined,
      sorter: sort.column === "created_at",
      showSorterTooltip: false,
      width: 140,
      render: (value) => formatDateForApi(value),
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateCasesSort({
            column: "created_at",
            direction: sort.column === "created_at" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getCases())
        },
      }),
      onCell: () => ({
        style: { minWidth: "140px" },
      }),
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      render: (value, item) => <p className={ `severity ${ item.status === "open" ? "active" : "" } ${ value }` }>{ getSeverityTitle(value) }</p>,
      sortOrder: sort.column === "severity" ? sort.direction : undefined,
      sorter: sort.column === "severity",
      width: 130,
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateCasesSort({
            column: "severity",
            direction: sort.column === "severity" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getCases())
        },
      }),
    },
    {
      title: "Support Type",
      dataIndex: "support_type",
      key: "support_type",
      render: (value, item) => (
        item.sla_ci ? (
          <Tooltip title={ item.sla_ci?.name }>
            <div style={ { display: "flex" } }>
              <Tag
              style={ {
                background: item.sla_ci.marker_bg_color,
                color: item.sla_ci.marker_text_color,
              } }
              >
                { item.sla_ci?.marker }
              </Tag>
            </div>
          </Tooltip>
        ) : <div style={ { textAlign: "center", width: "70px" } }>{"-//-"}</div>
      ),
      sortOrder: sort.column === "support_type" ? sort.direction : undefined,
      sorter: sort.column === "support_type",
      showSorterTooltip: false,
      width: 150,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateCasesSort({
            column: "support_type",
            direction: sort.column === "support_type" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getCases())
        },
      }),
    },
    {
      title: "Closing Date",
      dataIndex: "closed_at",
      key: "closed_at",
      render: (value) => <p className={ value === null ? "align-center" : "" }>{ value === null ? "—" : formatDateForApi(value) }</p>,
      sortOrder: sort.column === "closed_at" ? sort.direction : undefined,
      sorter: sort.column === "closed_at",
      showSorterTooltip: false,
      width: 140,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateCasesSort({
            column: "closed_at",
            direction: sort.column === "closed_at" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getCases())
        },
      }),
      onCell: () => ({
        style: { minWidth: "140px" },
      }),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      sortOrder: sort.column === "customer" ? sort.direction : undefined,
      sorter: sort.column === "customer",
      showSorterTooltip: false,
      width: 120,
      className: `customer-table-cell`,
      render: (value, item) => item.contractor ? (
        <Tooltip
          title={ (
            <div>
              <div>{item.contractor.official_name}</div>
              <div>{item.contractor.phone_number}</div>
              <div>{item.contractor.default_email}</div>
            </div>
          ) }
        >
          <div style={ { display: "flex", gap: 5 } }>
            <p>{ item.contractor.name }</p>

            {item.contractor.has_account && <img src={ hydraAccount } alt=""/>}

            {item.contractor.has_verified_account && <img src={ verified } alt=""/>}
          </div>
        </Tooltip>
        ) : "-/-",
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateCasesSort({
            column: "customer",
            direction: sort.column === "customer" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getCases())
        },
      }),
    },
  ]

  return (
    <Loading isLoading={ isCasesLoading } isColored height={ 200 }>
      <Table
        className="cases-table"
        columns={ columns.filter((col) => col.key !== (isAdmin ? "severity" : "customer")) }
        dataSource={ cases }
        rowKey={ (item) => item.id }
        onRow={ (record) => ({
          onClick: () => navigate(RouteLinks.PROTECTED.CASES.CASE + "/" + record.id),
        }) }
        pagination={ false }
        scroll={ { y: window.innerHeight - 164 - (document.getElementById("cases-header-cell")?.offsetHeight || 0) - (document.getElementById("cases-filter")?.offsetHeight || 0) } }
        tableLayout="auto"
      />
    </Loading>
  )
}

export default CasesTable;
