import { FC } from "react";

import { message, Tooltip } from "antd";

import { useAppSelector } from "store";
import { getCasesSelector } from "store/cases/cases.selector";
import { getUserSelector } from "store/user/user.selector";
import fileIcon from "data/icons/file.svg";
import linkIcon from "data/icons/link.svg";

import "./ContactInfoCard.scss";

const ContactInfoCard: FC = () => {
  const { currentCase } = useAppSelector(getCasesSelector);
  const { isAdmin } = useAppSelector(getUserSelector);

  return currentCase ? (
    <div className="contact-info-card-wrapper">
      <p className="contact-info-title">Contact Information</p>
      <div className="info-wrapper">
        <div className="info-item">
          <p className="title">Name:</p>
          <p className="value">{ currentCase.contact_name }</p>
        </div>

        <div className="info-item">
          <p className="title">Email:</p>
          <p className="value">{ currentCase.contact_email }</p>
        </div>

        <div className="info-item">
          <p className="title">Phone:</p>
          <p className="value">{ currentCase.contact_phone_number }</p>
        </div>

        {currentCase.email_cc && (
          <div className="info-item">
            <p className="title">CC:</p>

            <p className="value">
              { currentCase.email_cc?.map(email => (
                <div key={ email }>{ email }</div>
              )) }
            </p>
          </div>
        )}
      </div>

      { isAdmin && (
        <div className="task-link-wrapper">
          <p className="task-link-title">Task Link</p>
          <Tooltip title="Click to copy URL">
            <div
              onClick={ () => {
                try {
                  navigator.clipboard.writeText(window.location.href);
                  message.success("Link copied!")
                } catch (e) {
                  message.error("Something went wrong!")
                }
              } }
              className="task-link-item"
            >
              <div className="link-icon-wrapper">
                <img src={ fileIcon } alt="" />
                <img src={ linkIcon } alt="" />
              </div>

              <p className="link">{ window.location.href }</p>
            </div>
          </Tooltip>
        </div>
      ) }
    </div>
  ) : null;
}

export default ContactInfoCard;
