import { useEffect, useState } from "react";

import { Button, Checkbox, Form, Input, message } from "antd";

import api from "services/api.service";

import "./EmailSender.scss";

const EmailSender = () => {
  const [ form ] = Form.useForm();

  const [ error, setError ] = useState("");
  const [ isLoading, setIsLoading ] = useState(false);

  const [ id, setId ] = useState();
  const [ taskId, setTaskId ] = useState("");
  const [ status, setStatus ] = useState<{ status: string; message: string; }>();

  const enabled = Form.useWatch("enabled", form);
  const use_ssl = Form.useWatch("use_ssl", form);
  const use_tls = Form.useWatch("use_tls", form);

  const onCheckStatus = async (taskId: string) => {
    try {
      const response = await api.asyncTasksCheck(taskId);

      setStatus(response.data);
    } catch (e) {
      setError("Error");
    }
  };

  const onCheck = async () => {
    try {
      const response = await api.getSettingsEmailSenderCheck();

      setTaskId(response.data.task_id);

      setTimeout(() => {
        onCheckStatus(response.data.task_id);
      }, 1000);
    } catch (e: any) {
      setError(e.response.data.detail);
    }
  };

  const handleSubmit = async (values: { email: string }) => {
    try {
      setIsLoading(true);

      const response = id
        ? await api.updateSettingsEmailSender(values)
        : await api.createSettingsEmailSender(values);

      if (response.data) {
        message.success("Settings updated");
        setError("");
      }

      setIsLoading(false);
    } catch (e: any) {
      setError(e.response.data.error || "Something went wrong!")
      setIsLoading(false);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const onLoad = async () => {
    const response = await api.getSettingsEmailSender();

    if (response.data.id) {
      form.setFieldsValue(response.data);
      setId(response.data.id);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div>
      <Form
        className="email-sender"
        layout="vertical"
        onFinish={ handleSubmit }
        form={ form }
      >
        { error && ( <div className="login-error">{ error }</div> ) }

        <Form.Item
          name="enabled"
          valuePropName="checked"
        >
          <Checkbox>
            Enabled
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="host"
          label="Host"
          rules={ [ { required: true, message: "Required" } ] }
        >
          <Input
            placeholder=""
            disabled={ !enabled }
          />
        </Form.Item>

        <Form.Item
          name="port"
          label="Port"
          rules={ [ { required: true, message: "Required" } ] }
        >
          <Input
            placeholder=""
            disabled={ !enabled }
          />
        </Form.Item>

        <Form.Item
          name="host_user"
          label="Host user"
          rules={ [ { required: true, message: "Required" } ] }
        >
          <Input
            placeholder=""
            disabled={ !enabled }
          />
        </Form.Item>

        <Form.Item
          name="host_password"
          label="Host password"
          rules={ [ { required: true, message: "Required" } ] }
        >
          <Input
            placeholder=""
            disabled={ !enabled }
          />
        </Form.Item>

        <div style={ { display: "flex" } }>
          <Form.Item
            name="use_ssl"
            valuePropName="checked"
          >
            <Checkbox
              disabled={ use_tls || !enabled }
            >
              Use SSL
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="use_tls"
            valuePropName="checked"
          >
            <Checkbox
              disabled={ use_ssl || !enabled }
            >
              Use TSL
            </Checkbox>
          </Form.Item>
        </div>

        <div style={ { display: "flex", gap: "24px" } }>
          <Form.Item className="mg-btn-0">
            <Button
              type="primary"
              htmlType="submit"
              disabled={ isLoading }
            >
              Save settings
            </Button>
          </Form.Item>

          <Button
            type="default"
            onClick={ onCheck }
          >
            Check status
          </Button>
        </div>
      </Form>

      {status?.status ? (
        <>
          <div>
            We have attempted to send you a message to your email. Check the status of this process bellow. <br/>
            Is status is SUCCESS - check please your email inbox.
          </div>

          {/*<br/>*/}

          <Button
            type="default"
            onClick={ () => onCheckStatus(taskId) }
            style={ { margin: "10px 0" } }
          >
            Refresh
          </Button>

          <div>
            Status: {status?.status}
          </div>

          <div>
            Message: {status?.message}
          </div>
        </>
      ) : (
        <div>
          {JSON.stringify(status, null, 2)}
        </div>
      )}
    </div>
  );
};

export default EmailSender;
